
import Post5Summary from "../summary/Post5Summary";

const Post5 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Bătălii epice în bucătărie

O chema Ecaterina, dar eu îi spuneam Maia. Ea și soțul ei (Buni pentru mine) au fost, strict vorbind, un fel de bone, dar, de fapt, bunici adoptivi.
<figure>
<img src="./post5/maiaaandme.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Eu și Maia în sufrageria ei</i></figcaption>
</figure>

Când erau tineri, Maia și Buni au adoptat un băiat, probabil mǎrisor la momentul adoptiei. Când băiatul a devenit bărbat, s-a căsătorit și a avut copii, Maia si Buni au avut grijă de cei doi băieți ai lui până când au devenit adolescenți. În acel moment, a venit rîndul meu, pe atunci de un an sa intru în viața lor. Au avut grijă de mine și apoi, mai târziu, de fratele meu.
<figure>
<img src="./post5/maia.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Maia în tinerete</i></figcaption>
</figure>
Maia a fost casnică iar Buni era pensionar când i-am cunoscut eu. Făceau macrameuri la vânzare pentru a-și completa mica pensie. Nu stiu câte au vândut, dar cu siguranță mi-au dat si mie destule. Din fericire pentru mine, ea era și o bucătăreasă fantastică și facea niste mâncăruri moldovenesti minunate in ciuda lipsurile alimentare din acea vreme: 
borsul ei de sfecla era legendar, alivenci, poale in brâu, chiftele minunate, 
pâine si cozonaci, chiar si brânza facea in casa.
<figure>
<img src="./post5/macrame.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>O mică selecție a creațiilor lor pe care mi le-au oferit</i></figcaption>
</figure>
Maia era dintr-un oraș din Moldova, Vaslui. Eroul Maiei a fost cineva care este adesea considerat unul din românii cei mai importanti, Ștefan cel Mare, domnitor medieval al Moldovei. A domnit aproape 50 de ani, a câștigat zeci de bătălii împotriva Imperiului Otoman în expansiune la vremea aceea și a ctitorit multe mănăstiri pictate, acum locuri de patrimoniu mondial.
<figure>
<img src="./post5/stefan.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>Ștefan cel Mare și una dintre zecile de biserici construite în timpul lungii sale domnii</i></figcaption>
</figure>
În bucătăria ei mica, Maia aducea la viață aceste bătălii glorioase.

_Iată că vine Stefan pe calul său alb, cu armata sa de răzesi în spatele lui. Au săbii și scuturi, arcuri și săgeți. Caii lor își mușcă frâiele, se împing unul împotriva celuilalt și abia își pot conține energia._ 
Auzeam practic copitele cailor.

_El urcă pe deal și se uită peste vale. Erau turci cât vezi cu ochii. Vor fi înfrînti moldovenii noștri?_ Sunt cu sufletul la gura.

_Ștefan al nostru își scoate sabia și tună: "Veniti să salvăm Moldova noastra, fiii mei!”_

_Eu? Îmi apăr sărăcia și nevoile și neamul... <br>
Și de-aceea tot ce mișcă-n țara asta, râul, ramul,<br>
Mi-e prieten numai mie, iară ție dușman este,<br>
Dușmănit vei fi de toate, făr-a prinde chiar de veste!_

Ea citează o poezie celebră, de altfel despre un alt domnitor, dar nu conteaza; eu nu recunosc citatul dar ritmurile și rimele sunt memorabile.

_Și au pornit! Ştefan şi răzesii lui se îndreaptă spre dusman, iuti ca vântul._ 

De-abia mai respir.
Maia își pune mâinile împreună, își îndreaptă brațele și balansează o sabie imaginară printre oalele și vasele din bucătărie, descriind modul în care Stefan îi face pe dușmanii să cadă sau să fugă.

_Fugi de-aici, Suleiman Pașa, n-ai nicio treabă aici, în Moldova_, îndeamnă ea, împingând cu sabia o pungă de făină care îl reprezinta pe conducătorul armatei otomane, mai târziu sultanul Suleyman Magnificul.

Nu mai pot de emotii. _Dar a câștigat Stefan, Maia, a câștigat?_ .

_Da,  dar cu mari pierderi și apoi a construit o biserică frumoasă pentru sufletele celor căzuți în luptă_.

Cât ne-am bucurat că Moldova noastră a supraviețuit pentru a lupta în altă zi! Și am sărbătorit cu o altă plăcintă moldovenească delicioasă pe care ea o cocea.
Ea îmi descrie apoi frumoasa mănăstire, pictându-mi în minte toate imaginile cu Iisus și Maria, sfinți și îngeri și cu Ștefan însuși ținând un model al bisericii.
Sper că a văzut cel puțin una dintre aceste biserici cu adevărat.

Când am fost la școală, am aflat spre surprinderea mea că Ștefan cel Mare a trăit acum 500 de ani! Nu mi-a venit sa cred! Stiam că Maia e bătrână, așa că mi-am imaginat că trebuie să fi fost acolo pentru a vedea toate aceste bătălii!

La scurt timp după ce am învățat să citesc la școală, ea mi-a dat din cărțile ei. Avea o colecție remarcabilă de carti de Alexandre Dumas, multe editii din perioada interbelica, și am început cu Cei trei mușchetari. Ce mi-a mai plăcut! Ea croșeta pe canapea, Buni la masă coasea macrameuri, fratele meu pe covor cu niște jucării și eu eram lângă ea pe canapea, 
dar, în mintea mea, călătoream împreună cu d’Artagnan și eram preocupată de diamantele și onoarea Annei de Austria. Am trăit acele cărți.
<figure>
<img src="./post5/books.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>O mică selecție a cărților pe care mi le-a dat</i></figcaption>
</figure>
De îndată ce a văzut cât de mult îmi plac, a început să-mi dea cărțile ei, scriind mesaje de dragoste pe interiorul cărților.
De-a lungul anilor am citit colecția ei de romane de Dumas, inclusiv După 20 de ani, Vicontele de Bragelonne, Regina Margot. Printre multe altele, mi-a plăcut și o trilogie românească, acum uitată în mare măsură, despre aventurile unei familii de razesi ai lui Stefan, recunoscând-o drept sursa acelor bătălii din bucătărie. Mi-a plăcut să primesc cărțile pe care le-a citit și le-a iubit ea.
<figure>
<img src="./post5/dedicatii.png"
 style="display: block; margin: auto; width: 400px" />
 <figcaption style="text-align: center;" ><i>“Această carte o dăruiesc Doinitei cu toată dragostea să-și amintească de Maia” „Pentru Doinita Din Maia care te iubește mult”
  Unele dintre mesajele ei folosind numele meu de alint din copilărie, Doinița.
  </i></figcaption>
</figure>
Maia l-a pierdut pe Buni când eu aveam 9 ani. Pe la acea vârstă, am început să petrec mai mult timp cu prietenele după școală și probabil că am petrecut mai puțin timp la ea decât înainte. Tare rau imi pare acum.
La două zile după ce am împlinit 10 ani, acea inimă plină de dragoste a bătut pentru ultima data. Tocmai mi-a daruit Contele de Monte Cristo, setul de trei volume, și ultimul ei mesaj de dragoste.

Nu am avut niciodată ocazia să-i mulțumesc pentru tot ce mi-a dat. De la cărți, la poezie, istorie, la lucru manual, desen (ea m-a învățat un mod de a face fete și flori), cum se face brânza (ea făcea brânză proaspătă în bucătăria ei mică), o anumită placere a mâncarii, multe din mine vin de la ea.

Mi-am sortat cărțile recent și am avut gândul neplacut că, o data cu tehnologia și gusturile care se schimbă, plus circumstanțele mele, majoritatea cărțile mele românești probabil vor muri de moarte prin reciclare odată cu mine.

Asta m-a făcut să mă gândesc că poate am făcut totusi ceva important pentru Maia. Probabil că a văzut semnele că eram un mic soarece de biblioteca în devenire, că îi voi iubi cărțile la fel de mult cum le-a iubit și ea.
Acum cred că este un lucru mare să ai pe cineva caruia să ii transmiti obiectele pe care le iubești și să știi că le va prețui si iubi la fel de mult. Poate că am făcut măcar asta pentru ea.

Draga mea Maia, am iubit și am avut grijă de cărțile tale, deși nu am nevoie de ele ca să te tin minte!
				
`
		content = romText
	} else { 
const postText = `
# Epic Battles in the Kitchen 

Her name was Ecaterina but I called her Maia, an approximation of the word for granny in Romanian. She and her husband(Buni to me, also from grandad) were technically childminders, but, in fact, 
very loving, adoptive grandparents for me.
<figure>
<img src="./post5/maiaaandme.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Maia and I in the living room</i></figcaption>
</figure>

When they were young, Maia and Buni had adopted a boy (I think the boy was around 10 when this happened). 
When the boy became a man, married and had children of his own, Maia and Buni looked after his two boys until they were teenagers. 
At that moment, one year old me came into their lives and they looked after me and then, later on, after my brother. 

Maia has always been a housewife and he was retired during my time with them. 
Maia and Buni were also crafting decorative items to sell in order to supplement his small pension. 
I am not sure how many they sold, but they certainly gave me plenty. 
Luckily for me, she was also a fantastic cook, and prepared wonderful, regional dishes despite all the significant food shortages at the time.
<figure>
<img src="./post5/macrame.png"
     style="display: block; margin: auto; width: 300px" />
     <figcaption style="text-align: center;" ><i>A small selection of their creations that they gave me</i></figcaption>
</figure>
Maia was from a county called Vaslui from the eastern part of Romania called Moldova, different, but next to the current Republic of Moldova. Maia’s hero was someone that often comes at the top of the greatest Romanians ever polls, Stefan the Great, medieval prince of Moldova (then consisting of both Romanian Moldova and the current, separate country). 
He reigned for nearly 50 years, won tens of battles against the expanding Ottoman empire and oversaw the construction of many, over 40, painted monasteries, now world heritage sites. 
<figure>
<img src="./post5/stefan.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>Stefan the Great and one of the tens of churches built during his long reign</i></figcaption>
</figure>
In her tiny kitchen, Maia was bringing to life for me Stephan's glorious battles.

_Here comes Stefan on his white horse with his army of freemen behind him. They have swords and shields, bows and arrows. Their horses bite into their reins, push against each other and can barely contain their energy_. I could practically hear the hooves.

_He climbs the hill and looks across the valley. There were Turks as far as the eye can see. Will our Moldovan men be lost?_. This is edge of the seat stuff.

_Our Stefan takes his sword out and thunders “Come save our Moldova, my sons!” _

_Cause I defend the poverty and the needs of my struggling land <br>
And therefore all the rocks and streams and hills that guardian stand <br>
And all that grows and moves and breathes to me is ally true, <br>
While every blade of grass and stone is enemy to you!_

She is quoting a famous Romanian poem, the quote is lost on me but the rhythms and rhymes are memorable.

_And they're off! Stefan and his freemen charge towards the enemy, fast as the wind_. 
I can hardly breathe. 
She puts her hands together, straightens her arms and swings an imaginary sword among the kitchen’ pots and containers describing how Stefan makes his enemies fall or run away. 

_Run away, Suleiman Pasha, you have no business here in Moldova_, she admonishes, prodding with the sword a bag of flour standing in as the Ottoman army leader, later the sultan Suleyman the Magnificent.

My heart is in my mouth. _But did Stefan win, Maia, did he win?_ .
_Yes, he did but with great losses and  then he built a beautiful church for the souls of those that have fallen in the battle_.

How we cheered that our Moldova survived to fight another day! And we celebrated with another mouth-watering Moldovan cake that she was baking.
She then describes the beautiful church to me, painting in my mind all the images of Jesus and Mary, saints and angels, and of Stefan himself holding a model of the church, 
the heavenly blue of the background. 
I hope she saw at least one of these churches for real.

When I went to school, I learned to my dismay that Stefan the Great lived 500 years ago! I couldn’t believe it! I mean, I knew she was old, so I imagined she must have been there to see all these battles! 
 
Soon after I learnt to read at school she started me on her big collection of books, including some very nice, hard backs editions from the '30s. 
She had a good Alexandre Dumas section and I began with The Three Musketeers. How I loved it! I read it so many times, I knew some scenes by heart.
She’d crochet on the sofa, Buni at the table sewing their creations, my brother on the floor with some toys and I would sit next to her, riding along with d’Artagnan and worrying about the Anna of Austria’s diamonds and honour. I lived those books.
<figure>
<img src="./post5/books.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>A small selection of the books she gave me</i></figcaption>
</figure>
As soon as she saw how much I loved them, she started to gift me her books, writing loving messages on the inside of the books. 
Along the years I worked my way through her Dumas collection, including After 20 years, Vicomte of Bragelonne, Queen Margot. Among many others, I also loved a now largely forgotten Romanian trilogy about the adventures of a family of Stefan’s freemen fighters recognising it as the source of those battles in the kitchen. I loved getting the books that she has read and loved.
<figure>
<img src="./post5/dedicatii.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>“This book I give with all the love to Doinita to remember Maia” “For Doinita From Maia that loves you dearly”
	 Some of her messages using my pet name from childhood, pronounced Doinitza, little Doina.
	 </i></figcaption>
</figure>
She lost her beloved husband when I was 9. Around that age, I started to spend more time with friends after school and I spent probably less time at hers than before, really bad timing on my part. 
Two days after my 10th birthday, that heart full of love beated its last. She has just given me the Count of Monte Cristo set of three volumes and her last message of love.

I never got the chance to thank her for all she gave me. From the books, to poetry, history to crafts, drawing(she taught me a way of doing faces and flowers), a knowledge of how cheese is made(she was making fresh cheese in her tiny kitchen), a certain love of food, a lot in me is coming from her.

I sorted out my books recently and I had the uncomfortable thought that, because of the technology and taste changes, plus my specific circumstances,  my Romanian books will probably die a paper-recycling-death with me. 

This got me thinking that, maybe, I have done something important for Maia. She probably saw the signs that I was a little bookworm in the making, that I will love her books as much as she did.
I now think this is quite a big deal to have someone to pass on the things you love and to know that they will cherish them. Maybe I did at least this thing for her.

My dear Maia, I loved and took good care of your books though I don't really need them to remember you!  

`
    content = postText
	}


const summary = Post5Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post5;
