
import Post11Summary from "../summary/Post11Summary";

const Post11 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Înjurături curajoase

Îmi amintesc de bunicul meu patern, Ion, cunoscut și sub numele de Nițǎ în familie, ca un om liniștit și bun.  Avea o credință creștină simplă și neclintită („există o putere mare creatoare, Dumnezeu e in tot si toate”, parcă îl aud spunând). A fost unul dintre cei mai longevivi membrii ai familiei mele, apropiindu-se de 90 de ani când a murit. 

Mi s-a spus că familia lui provenea din Transilvania, județul Alba, când zona făcea parte din Imperiul Austro-Ungar. La un moment dat, pe la începutul secolului al XX-lea, familia s-a mutat, sau, chiar, a fost nevoită să se mute, într-o altă parte a României, un sat din Dobrogea. 
Am văzut un certificat de naștere al bunicului în care un sat din județul Constanța era scris ca loc de naștere si anul ca 1906, dar certificatul e datat din 1950, deci cine știe. Oricum, el a fost fiul cel mare al familiei și și-a luat în serios responsabilitatea de a avea grijă de frații săi (un frate și două surori pe care i-am cunoscut și eu, poate mai multi). 
Bunicul a fost doar patru ani la școală primara. Îmi amintesc că am auzit asta de la adulți când eram eu în clasa a patra și nu puteam înțelege tonul ușor disprețuitor: patru ani de școală sunt mulți, nu-i aşa?

În prima parte a vieții, aproximativ 45 de ani, Ion a lucrat pământul cu și pentru familia sa. Cum au obținut primul teren în Dobrogea? L-au cumpărat cu banii pe care i-au adus say a fost motivul pentru care s-au mutat în Dobrogea, un fel de înțelegere pentru pământ? Nu știu, dar până la urmă, familia a dobândit mult pământ de-a lungul anilor. Au investit tot timpul în a cumpăra din ce în ce mai mult teren și aveau cel puțin 10 hectare (acesta era maximul care putea fi recuperat în anii 1990 dar am auzit ca ar fi fost mai mult).   
Pe la 20 și un pic de ani, Ion s-a căsătorit cu o femeie pe nume Maria și au avut împreună trei fii, tatăl meu fiind cel mai mic.
<figure>
<img src="./post11/agriculture.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Lan de porumb si agricultura primitive pe care au practicat-o(copyright-ul autorului fotografiei)</i></figcaption>
</figure>
Nu știu exact ce culturi aveau și în ce proporții . Am auzit povești despre prǎșirea epuizanta a porumbului, pământul nisipos și argilos, întărit nemilos de soarele arzător, rând nesfârșit după rând nesfârșit; povești despre recolte de pepeni, roșii și galbeni, atât de abundente încât nu știau ce să mai facă cu ei, hrănindu-si toate animalele porci, vaci, oi, pui cu pepeni și încercând diverse conserve și gemuri de pepene; povesti despre munți de struguri pe care copiii îi călcau în picioare inainte de a fi pus la teasc sa faca vin; sau de grămezi de prune  din care făceau țuica; am auzit că au avut si o cârciuma la un moment dat. 
Știu că au angajat alți săteni pentru a lucra pământul, dar membrii familiei făceau întotdeauna și ei munca istovitoare; de chinurile ei si-au adus aminte toata viata si parcǎ încǎ o simteau, zeci de ani mai târziu. Casa lor era modestă, din chirpici (cărămidă din pământ și paie uscate la soare), i-am văzut și eu ruinele în copilărie . 

Când comuniștii au venit la putere, proprietatea, inclusiv pământul, era un furt. Nu numai că pământul le-a fost confiscat, dar Ion a fost trimis la muncă forțată la [canal](https://en.wikipedia.org/wiki/Danube%E2%80%93Black_Sea_Canal#First_attempt_(1949%E2%80%931953)).
<figure>
	<img src="./post11/canal.png"
	style="display: block; margin: auto; width: 450px" />
	<figcaption style="text-align: center;" ><i>Construirea canalului [Credit](https://adevarul.ro/stiri-locale/constanta/foto-canalul-dunare-marea-neagra-in-cifre-1423093.html)</i></figcaption>
</figure>

Un istoric britanic estimează ca un milion de români au fost închiși în diferite închisori și lagăre de muncă, o buna parte la canal.
Din fericire, Ion a supraviețuit anilor petrecuți la canal. Ar fi trebuit să-i pun mai multe întrebări despre timpul petrecut acolo.  

in 1954, familia a început să se mute la București. Mai întâi, doi fii, cel mare si cel mic, în vârstă de 24 și 14 ani la acea vreme, s-au mutat primii. După un timp, a urmat restul familiei extinse. 
Ion a avut câteva slujbe modeste în București cât a putut munci, înainte de a se pensiona, cu o pensie infima. S-a descurcat financiar foarte greu la bătrânețe și a avut nevoie de ajutorul fiilor săi pentru a supraviețui. 
Deși el nu avea şcoala, avea un mare respect pentru ea („ai carte ai parte” spunea deseori). Și-a încurajat fiii să facă școala, ceea ce au făcut, deși într-un mod mai neobișnuit (aceasta este o poveste pentru altă dată).
Spre deosebire de alți oameni care au ajybs în lagărele de muncă comuniste, el nu vedea în roz nici vremurile precomuniste. Când partidele liberal și țărănist au fost reînființate în 1990, el spunea ca sunt toti pungasi. Se pare că încă o poveste acolo despre care nu m-am gandit sǎ  întreb mai multe.

<figure>
	<img src="./post11/peles.png"
	style="display: block; margin: auto; width: 350px" />
	<figcaption style="text-align: center;" ><i>Bunicii mei paterni si cei trei fii in 1967, mai eleganti decît i-am vǎzut vreodatǎ</i></figcaption>
</figure>

Cândva spre sfarsitul anilor ’70, fiul mijlociu al lui Ion, unchiul meu George, pe atunci contabil la o companie petrolieră românească, a avut ocazia să lucreze în străinătate. Asta era ceva incredibil pe vremea aceea! Dar George avea o patǎ neagrǎ la dosar, statutul de „ dușman al poporului” al tatălui său. Cumva, George și-a convins șefii să-i dea o șansă, dar oficialii de partid au vrut să vadă mai întâi dacă inamicul, Ion, a fost reeducat prin  pedeapsa lui.

Unchiul meu mi-a spus mai târziu cum s-a desfășurat întâlnirea. El il implorase: 
„_Tǎticule_ (chiar îi zicea așa, chiar și ca adult)_, trebuie să le spui că înțelegi, comuniștii au avut dreptate și că te căiești pentru ceea ce ai făcut, ca ai exploatat poporul. Te rog, tǎticule, este foarte important! Spune-o doar o dată, nu contează_”. Ion dădu din cap. Pe vremea aia, și o glumă împotriva regimului putea fi periculoasă, frica era in floare, poate nu atât de mult cât în anii de final ai comunismului, dar oricum foarte prezentǎ.

Doi oficiali de partid (bănuiesc că acestia nu erau activiști de top, ci probabil niste politruci mai mǎrunți) au venit în cele două camere modeste ale bunicului meu. Ion stătea așezat pe banca lui, cei doi oficiali pe scaune și George stând nervos în picioare. 

„_Așadar, tovarășe Onuț_”, a început unul dintre oficiali, „_vezi acum greșelile pe care le-ai facut? Înțelegi de ce comuniștii sunt buni și cum se gândesc să-ți trimită fiul în străinătate? Înțelegi asta acum?_”.
Bunicul meu i-a luat ceva timp să răspundă. 

„_Comuniştii, da. Fu..-i in c-r pe ma-sa!_” spuse el furios. Toata lumea a înlemnit, George a trebuit să se așeze în acest moment. 

„_Ce au avut ei cu caii mei să-i împuște, f..tu-i pe toți criminalii comuniști?_”. Aceasta se referea la un [episod rușinos](https://romanialibera.ro/lifestyle/food/cum-au-masacrat-comunistii-jumatate-de-milion-de-cai-185934/) la începutul comunismului în satele românești când jumătate din milioanele de cai au fost împușcați.
Sovieticii au adus, sau trebuiau să aducă, tractoare pentru a lucra pământul și după logica asta, de cai nu mai era nevoie, așa ca trebuie să fie împușcați. 

„_Mi-au luat animalele și le-au adus la colectivă și le-au lăsat să moară de foame, nenorocitii ăia, f..i eu pe ei! Florica, mi-a ținut cei trei fii în viață în timpul războiului cu laptele ei și au luat-o și au lăsat-o să moară de foame și sete! Ce v-a făcut Florica?! Conspira împotriva orânduirii?_” continuă Ion supărat, referindu-se la cea mai bună vacă a lui. Animalele satenilor au fost deseori confiscate și, se pare ca in acest caz, au fost duse undeva, dar nu li s-a dat nici hrana sau apa și au murit de foame si sete, bietele animale. Nu știu cît de reprezentativ e acest caz.

Timp de vreo 10 minute, după cum mi-a povestit George, Ion a continuat să înjure comuniști și să se plângă de ce li s-a întâmplat cailor, Floricai si celorlalte animale. În mod curios, nu a menționat nimic despre anii de canal, doar despre crimele împotriva animalelor sale. Cumva, oficialii au plecat (sunt sigur că nu s-au despărțit prieteni) și George mi-a spus că nu l-a lăsat inima să-i reproșeze tatălui său această tiradă.
<figure>
<img src="./post11/grandparents.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>Bunicii mai si cu mine cu un brad care mi s-a parut feeric. Scena descrisa a avut loc la cîtiva ani mai tîrziu de la data aceastei fotografii</i></figcaption>
</figure>
Surpriza a fost că această scenă nu a avut vreo consecință. Poate a fost un pic de milă din partea activiştilor pentru un bătrân care a suferit deja destul? 
Sau lene cǎ trebuiau să facă rapoarte suplimentare pentru a explica această întâlnire neobişnuită și pentru a nota toate înjurăturile? 
Poate faptul că a pomenit numai despre animale a fost ceea ce a contat? Oricum, nu au existat alte consecințe pentru bunicul meu și, câțiva ani mai târziu, unchiul meu și-a primit deplasarea în străinătate.  

In ciuda nedreptățile suferite, mă bucur că bunicul meu a supraviețuit muncii grele și sistemului și a avut șansa de a le spune în față ce credea despre ei. Nu a fost chiar dreptatea pe care o merita, dar sunt sigur că a însemnat mult pentru el să-și descarce furia și durerea acumulate de-a lungul deceniilor și să le spune pe fațǎ, româneşte, cum stǎ treaba.


`
		content = romText
	} else { 
const postText = `
# Brave Swearing

I remember my paternal grandfather, Ion, also known as Nițǎ to the family, as a quiet and kind man. 
He had a simple and unwavering Christian faith (“there is a big, almighty power above us all”, I can still hear him saying). 
He was one the most long-lived of my family, pushing 90 when he died. 
<br/>
<br/>
I was told that his family originated from Transylvania, Alba county, back in the days before WW1 when it was part of Austro Hungarian empire. 
At some moment, in the early part of the 20th century, the family moved, or, even, was forced to move, to a different part of Romania, a village in Dobrogea, Constanta county, by the Black Sea. 
I saw a birth certificate of my grandfather listing a village in Constanta county as his birth place in 1906 though the certificate itself was written in 1950, so who knows. 
Anyway, he was the eldest son( birth order is quite significant in traditional Romanian society), and took seriously the responsibility of looking after his siblings (a brother and two sisters that I met). 
He had only four years of schooling. I remember hearing this from grown ups when I was in year 4 myself and I could not understand the dismissive tone: four years of school is quite advanced, isn't it?

During the first part of his life, for about 45 years, Ion worked the land with and for his family. 
How did they get the first land in Dobrogea? Did they buy it with money they brought from Transylvania or was the reason they moved to Dobrogea some sort of land deal? 
I don’t know how, but eventually the family acquired a fair bit of land over the years. 
They poured all their resources into buying more and more land, and had at least 10 hectares or 24 acres (this was the maximum that could be recovered in the 1990s but I remember hearing from relatives
that they used to own a lot more).   
In the late 1920s, Ion married a woman called Maria and they  had three sons, with my father being his youngest.
<figure>
<img src="./post11/agriculture.png"
	style="display: block; margin: auto; width: 300px" />
	<figcaption style="text-align: center;" ><i>Maize crop and the rather basic agriculture they practiced(copyright belongs to the owner)</i></figcaption>
</figure>
I don't know precisely how they farmed, where by "they", I mean my paternal grandparents, my two uncles and dad, and even my grandfather siblings and their families (a few of them I met as a child). 
I heard stories about exhausting manual digging of the maize, the sandy and clay soil baked solid by the burning sun, endless row after endless row;
stories about mountains of grapes waiting to be crushed, first by the feet of the children and then by presses to be made into wine; 
stories about big piles of plums waiting to be made into spirits;
stories about melons crop, watermelons and cantaloupes, so abundant that they didn’t know what to do with them, feeding them to all their animals, pigs, cows, sheep, chicken, and trying various preserves and jams.
I heard stories that they used to own a tavern where they would sell their wine and spirits, that Ion doing the front of the house role and his wife, Maria, running it. 
<br/>
I know they employed other villagers to work their land, but the family always did the back breaking work themselves too. 
The memory of that exhaustingwork stayed with them for decades and they would still recall it in my childhood.
Their house was a modest adobe or mudbrick dwelling(sun-dried earth and straw), the remains of which I saw once in my childhood. 
<br/>
When the communists came into power, property, including land, was a theft. Not only was the land confiscated, but Ion was sent to a labor camp, the [Danube to Black Sea canal](https://en.wikipedia.org/wiki/Danube%E2%80%93Black_Sea_Canal#First_attempt_(1949%E2%80%931953)) and labeled enemy of the people. From this source, a British historian estimates that one million Romanians had been imprisoned in various prisons and labor camps, including those on the path of the canal.

<figure>
	<img src="./post11/canal.png"
	style="display: block; margin: auto; width: 450px" />
	<figcaption style="text-align: center;" ><i>The construction of the canal [Credit](https://adevarul.ro/stiri-locale/constanta/foto-canalul-dunare-marea-neagra-in-cifre-1423093.html)</i></figcaption>
</figure>

Thankfully, Ion survived his years at the canal. I wish I had asked him more questions about his time there.  
From 1954, the family started to move to Bucharest. First Ion’s eldest and youngest sons, aged 24 and 14 at the time, moved first. After a while, the rest of the extended family followed. 
Ion did a few menial jobs in Bucharest for as long as he could, before  retiring, with hardly any pension. He struggled financially in his old age and needed help from his sons to get by. While uneducated himself, he had a great respect for education as a way of making a better life (“ai carte, ai parte” he’d say or "knowledge is power"). 
He encouraged his sons to do it, which they did, though in a slightly unusual way (that’s a story for some other time).
Unlike other people that were sent to communist labor camps, he wasn’t starry eyed about the pre-communist times. When the liberals and peasant parties were reinstated in 1990, he thought they were all hustlers and crooks. 

<figure>
	<img src="./post11/peles.png"
	style="display: block; margin: auto; width: 350px" />
	<figcaption style="text-align: center;" ><i>My grandparents and their sons in 1967, visiting a royal castle</i></figcaption>
</figure>

Sometime in the late ‘70s, Ion’s middle son, my uncle George, by then an accountant for the Romanian petroleum company, had the opportunity to work abroad, in an Arab country.
Romania had expertise in the extraction and processing of crude oil and deals were 
struck with at least a few countries, Algeria and Libya were those my uncle eventually went to.   
This was incredibly exciting and very lucrative for him! <br/>
But George had a  black mark on his file because of his father's “an enemy of the people” status. 
Somehow, George convinced his bosses to give him a chance, but the party officials wanted to first see if the enemy, Ion, had learned the lessons from his punishment.
At that time, the slightest political joke or protest could land you in prison.

My uncle later told me how the meeting went. He had pleaded: “_Daddy_(he really called him like that, even as a grown man), _you must tell them that you understand the communists are right and you repent for what you did. Please, daddy, it’s really important! Just say it once, it doesn’t matter_”. Ion nodded his agreement.

Two party officials (I think it’s fair to say, these were not the top brass but more likely the bottom of the pile) came to my grandfather's modest two  rooms. Ion was seated on his bench, the two officials on the chairs and George nervously standing. 

“_So, comrade Onuț_” started one of the officials, “_do you now see the errors of your ways? Do you understand why communists are good and how they think of sending your son abroad. Do you understand this now?_”. My grandfather took a while to respond. 

“_Communists, yes. F.ck them up their ars..!_” he answered angrily. Three jaws hit the floor at the same moment with George needing to sit at this point. 

“_What did they have with my horses to shoot them, f.ck all the communist criminals?_”
This referred to a [shameful act](https://romanialibera.ro/lifestyle/food/cum-au-masacrat-comunistii-jumatate-de-milion-de-cai-185934/ ) at the beginning of the communism in the Romanian villages when half of the millions of horses were shot. The Soviets brought, or were supposed to bring, tractors to work the land and, by this twisted logic, the horses were no longer needed, so they should be shot. 

“_They took my animals and brought them to the collective farm and they left them to die of hunger, those f.ckers! Florica, she kept my three sons alive during the war with her milk and they took her and left her to die of hunger and thirst! What has Florica done to you?! Was she a traitor, conspiring against the regime?!_” continued Ion angrily, referring to his best cow. 
Animals were often confiscated with little thought and, it sounds like, in this case, they were brought somewhere but not given any feed or water so they died suffering. I have no idea how representative this case was.

For about 10 minutes, according to George, Ion carried on swearing at the communists and ranting about the horses, Florica and all the other animals. 
Curiously, there was no mention of his years at the canal, just the crimes against his animals. 
Somehow, the officials left (I am sure the goodbyes were a bit awkward) and George said he didn’t have the heart to reproach his dad this outburst.
<figure>
<img src="./post11/grandparents.png"
	style="display: block; margin: auto; width: 400px" />
	<figcaption style="text-align: center;" ><i>My grandparents and I with the sorry looking Christmas tree that looked beautiful to me. This was taken a couple of years before this outburst</i></figcaption>
</figure>
The surprising thing is that this scene appeared to have no consequence. Maybe there was a bit of pity from the party officials, for an old man that has already suffered enough? Or was it they had to do extra paperwork to explain this unconventional encounter and write down all the swearing? Maybe the fact that it was about the animals that has saved him? 
Anyway, there were no further consequences for my grandad and, a couple of years later, my uncle got his assignment abroad.  

For all the injustices that he suffered, I am glad my grandfather survived the hard labor and the system that introduced it and that he got his chance to tell to some representatives of the system what he thought of them in this salty way. It must have felt so good to unleash the anger and suffering accumulated over decades.

Speak truth to power! And swear to its face if you dare! 
`
    content = postText
	}


const summary = Post11Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post11;
