// Post3.js
import Post3Summary from "../summary/Post3Summary"


const Post3 = (lang) => {
	let content = ''
    if (lang === 'rom') {
     const romText = 
	 `
# Scrisori de demult 📨
Fragmente de scrisori scrise în 1959 și 1960 de Constanța, străbunica mea, adresate nepoatei sale, Mariana (mama mea)

Aceste scrisori au fost scrise de Constanța atunci când nepoata ei avea, mai întâi, 14 ani, apoi 15 ani. 
Am văzut aceste scrisori când eram copil. Mi-au plăcut caligrafia frumoasă, paginile îngălbenite, 
formatul mic, dens, plin de scris. 
Am răsfoit scrisorile atunci, dar abia recent le-am citit atent, făcind legatura dintre cuvintele ei si evenimentele 
care au avut loc înainte.
Scrisorile conțin știri normale de familie, planuri de viitor și sfaturi practice, 
dar sunt si câteva paragrafe care mi s-au părut remarcabile, adînci, frumoase, pline de o intelepciune eterna.<br>
Am selectat si inclus câteva dintre acele fragmente aici, unele cu citeva explicatii. 
Iubesc ce a scris dar apreciez și ceea ce nu era acolo: fără amărăciune, plângeri sau deznădejde (si daca avea cineva motiv sa se planga de nedreptatea sortii, 
Constanta sigur avea motiv!); în schimb, 
un mesaj puternic de rezistență și integritate pentru o tânără fată. 
Cred că ce i s-a intimplat Constanței inainte de a scrie aceste scrisori le face și mai remarcabile.
<figure>
<img src="./post3/letters.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i>Scrisorile Constanței</i></figcaption>
</figure>

Iată cuvintele Constanței către nepoata ei:

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Ai încredere în tine, fii buna și curagioasa! Citesc o carte care vorbește despre Indira Gandhi și asta a spus ea: „fii curajos! Curajul este singurul fundament durabil al caracterului. Fără curaj, nu există moralitate, religie, dragoste. Nu poti urma cărările adevărului sau ale iubirii atâta timp cât esti sub imperiul fricii”._

Nota mea: Având în vedere [biografia](https://en.wikipedia.org/wiki/Indira_Gandhi) Indirei și faptul că a devenit prim-ministru în 1966, este greu de explicat cum citea Constanța despre ea, într-un sat din România comunistă în ianuarie 1960. 
Chiar și cu puterea internetului nu am putut găsi fix acest citat, dar am gasit unele similare, deși mai puțin frumoase, în opinia mea, care îi sunt atribuite precum „Fără curaj, nu poți practica nici o altă virtute.” sau „Curajul este cea mai importantă dintre toate virtuțile pentru că fără curaj, nu poți practica nicio altă virtute în mod constant."

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Pentru a putea rezista dușmanilor care caută să-l doboare, trupul trebuie să fie puternic, bine hranit, pentru că slăbirea corpului slăbește și mintea, aceasta nu mai poate înțelege sau reține cunoștințele care i-au fost încredințate.
Cu câtă plăcere te uiți la o trăsură trasă de cai la care sunt înhămați niște cai puternici și vioi, și ce milă și dezgust îți inspiră altul, cu bieți cai, atât de slabi încât le poți număra coastele și ce dispreț și mânie ai pentru proprietarii lor.
Același lucru este valabil și pentru animalul cel mai superior, dar rațional, omul. Așa este greșită ideea că a fi excesiv de slab este mai bine sau mai frumos._

Nota mea: Constanța a crescut cu trasuri trase de cai si a avut una mica ca aceasta [sareta](https://m.ghidul.ro/caisitrasuri/produse-servicii/trasuri-47154/sareta-85190)
 pentru ea și fiica ei, Aurelia.

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~


_Recent am citit asta: <br>
Cînd Dumnezeu te are drag<br>
Te ia in paza milei sfinte<br>
Ca arme de-a lupta inainte<br>
Nu-ti da nici pietre, nici ciomag,<br>
Îti da pricepere si minte. <br><br>
Acela ce si-n fericire<br>
Nu-si pierde capul, si-i cinstit,<br>
Cel ce ramîne umilit<br>
Cînd are avere si mārire,<br>
Si-acel ce-si tine juramintul<br>
Si fatā cu dusmanii sai,<br>
Tu da-mi tovarasi pe acesti trei<br>
Si-ti biruesc si eu pamîntul.<br><br>
&nbsp;&nbsp;&nbsp;&nbsp;Din Antologia sanscrită, tradusă de George Cosbuc<br>
Mă veți întreba ce rost au aceste versuri pe care ti le-am trimis. 
Iată răspunsul: mi-au plăcut foarte mult și am învățat din conținutul lor trei acte de mare valoare și că Dumnezeu îi iubește pe cei care trăiesc după acele valori: cinstea, smerenia sau modestia și sfințenia și veridicitatea cuvântului dat.<br>
Cu ocazia zilei de 1 Decembrie, il rog pe Dumnezeu să te ia sub ocrotirea sfintei milostiviri și să cimenteze în ființa ta interioară aceste trei calități: onoare, modestie și onestitate și, cu ajutorul lor, să depășesți obstacolele întâlnite în viață. <br>
Nu fi supărata că nu am putut fi acolo cu tine; deși absenta fizic, gândurile, grija și dorul meu pentru tine se îmbină cu umbra ta și te însoțesc invizibil pretutindeni ca paznicii tăi tăcuți._

Notitele mele:
1 decembrie este ziua de naștere a Marianei și ziua morții Aureliei, fiica Constanței.<br>
Traducătorul român, George Coșbuc este un poet major a cărui operă originală este studiată în școlile românești. 
A murit în 1918, așa că se poate ca si Constanța sa fi avut o ediție a traducerilor sale. <br>
Bazat pe o ediție recentă, aceasta nu este o poezie așa cum sugerează scrisoarea. 
Constanța a combinat și editat, probabil în scopuri educaționale, două intrări separate într-o secțiune numită Proverbe, Maxime din cartea Antologie sanscrită.


# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Fructele sunt terminate în livezi, viile au fost culese și grădinile sunt dezbrăcate de frumusețea lor. 
Păsările călătoare și-au luat de mult zborul spre locurile calde unde vor petrece iarna, 
iar în locul lor se află copiii mici care umplu străzile și fac să răsune aerul de ciripitul și chiuitul lor, când vin și pleacă de la școală.
Nu-ți face griji pentru mine, draga mea, pentru că Dumnezeu mă ține pe picioare și sunt obișnuită cu orice greutăți și înarmată cu suficientă răbdare pentru a suporta orice greutate._

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Câmpurile sunt un verde crud, copacii sunt încărcați cu flori. După o rafală de vânt, pe tot pământul nostru se întinde un strat alb ca zăpada, alcătuit din aceste delicate foi si petale. 
Oamenii sunt împrăștiați ca furnicile în toate părțile, fiecare lucrând unde și pentru ce trebuie făcut. 
Ghioceii albi au trecut foarte repede si liliacul nu s-a deschis bine pana acum. 
Acele flori albe nu au ieșit încă, mă tem că ar fi murit. Ia-le semințele în această vară pentru că ai spus că ai multe în grădina ta. 
Mi-ar plăcea să cresc acele flori de la tine, dragostea mea._

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Gândurile mele pure de dragoste te urmăresc, fac un cerc în jurul tău și formează un zid puternic înconjurător pentru a te proteja de orice rău. 
Pășește cu credință în ajutorul Atotputernicului, în al 15-lea an al vieții tale. Fie ca El să iti îndrume pașii pe căile bune și să iti dea putere în luptele vieții._

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Ai grijă de sănătatea ta, citește-ți cărțile și ai răbdare, pentru că viața este țesută mai mult din necazuri și suferințe, dar trebuie să le eviți, iar când nu le poți evita atunci trebuie să lupți, să le învingi și să reușești, fără a fi tu doborâta._

## Povestea din spatele scrisorilor
Constanța s-a născut în 1892 în județul Argeș, România, într-o familie de proprietari de pământ relativ înstărită.
A devenit invătătoare, o meserie foarte respectată pe atunci, mai ales într-un sat. 
Constanța s-a căsătorit cu un coleg, Pârvu. Pârvu a luptat în primul război mondial ca ofițer și a fost ucis în acțiune în 1917, în timp ce Constanța era însărcinată. 
Povestea lui este [aici](./bookswar)
<figure>
<img src="./post3/family.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Constanța ca fată și familia ei, deci străbunica și stră-străbunicii mei.
Fotografia rebuie să fi fost în jurul anului 1910.
</i></figcaption>
</figure>
Fiica lor, Aurelia, s-a născut în ianuarie 1918. A crescut întîi în sat, apoi la Pitesti dupa care a plecat la București de îndată ce a împlinit 18 ani. 
Aurelia a stat cu matusa ei, sora Constantei. Am scris povestea remarcabila a acestei surori [aici](./tablecloth).

<figure>
<img src="./post3/constanta.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Constanța ca femeie tinara, fotografii facute, probabil in jur de 1910-1920, colorizate de mine.
</i></figcaption>
</figure>
După câțiva ani plini de evenimente petrecuți în capitală, începutul celui de-al Doilea Război Mondial a readus-o pe Aurelia în sat. 
O sa scriu si povestea ei remarcabila. Aurelia a cunoscut și în 1944, s-a căsătorit cu un bărbat pe nume Constantin(povestea inceputului lui e [aici](./fringe)). 
<figure>
<img src="./post3/bunici.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Aurelia si Constantin, bunicii mei materni in 1945.
</i></figcaption>
</figure>
Mai târziu Aurelia a rămas însărcinată cu Mariana, mama mea. În mod tragic, Aurelia a murit la naștere în decembrie 1945, lăsând-o pe Constanța in grija nepoatei nou-născută. 
Constanța a crescut-o pe Mariana timp de 10 ani.<br>
După al Doilea Război Mondial, comuniștii au ajuns la putere în România. Orice persoană mai bogată era automat dușmanul poporului. 
Tot pământul Constanței a fost confiscat și casa ei, cea mai bună din sat, a fost rechiziționată pentru folosinta oficialilor de partid. 
Bunica și nepoata au fost lăsate să stea în bucataria de vara neîncălzita. 
Mai târziu au putut sa locuiasca în podul casei. Spre sfarsitul vietii Constanței, oaspetii nepoftiti au plecat din casa ei.

Când Mariana avea 10 ani, tatăl ei i-a cerut să se mute cu el și cu a doua lui soție în casa din București.
Mariana și-a vizitat bunica în sat în timpul vacanțelor de vară și si-au scris una alteia din 1956 până la moartea Constanței în 1968. 
Doar scrisorile din 1959-1960 au supraviețuit. <br>
<figure>
<img src="./post3/candm.png"
style="display: block; margin: auto; width: 300px" />
<figcaption style="text-align: center;" ><i> Constanța si Mariana in fata casei lor intr-o vacanta, probabil 1964.
</i></figcaption>
</figure>
Paginile îngălbenite păstrează o particica din sufletul Constanței, erudiția, dragostea și înțelepciunea ei. 
Ele sunt o inspirație pentru mine si sper sa fie pentru toti urmasii Constanței si pentru toti cei ce au citit pînă aici.
`
	content = romText
	} else {
 	const engText = 
`
# Letters, lives, love 📨

My great-grandmother, Constance, wrote to her granddaughter, Mariana (my mother) throughout her life but only letters from 1959 and 1960 survive
Mariana was then first 14, then 15 years old. 
<br/>
As a child, I saw these letters, maybe 20 in number, and loved their beautiful calligraphy, the small, yellowed and densly written pages. 
It was only a year ago that I read them properly and I was captivated by their beauty. 
The letters contain normal family news but there are a few paragraphs that I found wise, tender and timeless. 
I included here a few of those fragments, with some explanations and then the backstory that make the letters even more remarcable. 
I love the letters and the strong message of resilience, integrity and hope for all the tragedies in Constance's life. 
<figure>
<img src="./post3/letters.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i>A few of Constance's letters</i></figcaption>
</figure>

Here are some of Constance's words for her granddaughter:

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Trust yourself, be kind and be brave! I'm reading a book that talks about Indira Gandhi and this is something she said: "be brave! Courage is the only lasting foundation of character. Without courage, there is no morality, no religion, no love. One cannot follow the paths of truth or love as long as one is under the empire of fear”.  Be brave, my darling girl!
_

My note: Given Indira’s [biography](https://en.wikipedia.org/wiki/Indira_Gandhi) and the fact that she became PM in 1966, it is hard to explain how Constance, in a village in communist Romania, was reading about her in January 1960. 
Even with the power of the internet I couldn't find this exact quote but there are some similar ones, though less compelling in my opinion, that are attributed to Indira Gandhi like “Without courage, you cannot practice any other virtue.” or “Courage is the most important of all the virtues because without courage, you can’t practice any other virtue consistently".



# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Recently I read this:<br>
When God holds you close in His embrace, <br>
He keeps you safe in sacred Grace. <br>
As weapons for the battles you may face, <br>
He doesn’t give you stones and cudgels <br>
But wit and skill against your struggles.<br> <br>
If in happiness you keep your sense <br>
And stay true and honest, no pretence <br>
If modest and humble you can stay, <br>
When fame and riches come your way<br>
If you keep your promises sincere, <br>
Even to your foes, without fear, <br>
With companions like these here,<br>
You will conquer worlds, far and near.<br>
&nbsp;&nbsp;&nbsp;&nbsp;From the Sanskrit Anthology, translated by George Cosbuc<br><br>
You will ask me what is the point of these verses that I sent you. Here is the answer: I liked them a lot and I learned from their content three acts of great value and that God loves those who live by those values: honesty, humility or modesty and the sanctity and truthfulness of your given word.<br>
On the occasion of December 1st, I ask God to take you under the protection of the holy mercy and to cement in your inner being these three qualities: honour, modesty and honesty, and with their help, to overcome the obstacles encountered in life.<br>
Don't be upset that I couldn't be there with you; although absent in my body, my thoughts, care and longing for you are merged with your shadow and invisibly accompany you everywhere as your silent guardians.
_

My notes:
<br/>
1st of December is the granddaughter, Mariana’s birthday and Aurelia’s, Constance ‘s daughter and Mariana's mother, death day.<br>
The Romanian translator, George Cosbuc is a major poet whose original work is studied in Romanian schools. His translations from Sanskrit literature are rarely mentioned. <br>
Based on a recent edition of the Sanskrit Anthology, this is not one poem as the letter implies. It looks like Constance combined and edited two separate entries from a section called Proverbs, Maxims, probably for educational purposes. 
The English translation, such as it is, is my own as I could not find one for these verses.


# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_The fruits are finished in the orchards, the vines have been picked and the gardens are stripped of their beauty. The migratory birds have long taken their flight to the warm places where they will spend the winter, and in their place there are the little children who fill the streets and make the air resound with their chirping and hooting, when they come and go from school.
Don't have any worries about me, my darling, because God keeps me on my feet and I am used to every hardship and armed with enough patience to bear any weight.
_

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_In order to be able to resist the enemies that seek to bring it down, the body must be strong, well-nourished, because weakening the body also weakens the mind, it can no longer understand or retain the knowledge entrusted to it.
With how much pleasure you look at a horse-drawn carriage to which some strong and lively horses are harnessed, and what pity and disgust inspires you another, with poor horses, so weak you can count their ribs and what contempt and anger you have for their owners.
The same is true of the most superior but rational animal, man. This is how wrong is the idea that being excessively thin is better or more beautiful.
_

My note: Constance grew up with horse drawn carriages and had a small one like this ([sareta](https://m.ghidul.ro/caisitrasuri/produse-servicii/trasuri-47154/sareta-85190
)) for her and her daughter, Aurelia]

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_
The fields are soft green, the trees are loaded with flowers, dusting the ground. After a burst of wind, a snow-white layer is spread all over our land, made up of these delicate sheets of petals. The people are scattered like ants in all parts, each working where and for what needs doing. The white snowdrops passed very quickly and the liliac has not opened well until now. Those little white flowers have not come out yet, I fear they might have died. Take their seeds this summer because you said you have a lot in your garden. I would love to grow those flowers from you, my love.
_

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_My pure thoughts of love follow you, make a circle around you and form a strong surrounding wall to protect you from any evil. Step with faith in the help of the mighty One, in the 15th year of your life. May He guide your steps on the good paths and give you strength in the struggles of life.
_

# ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~ ~

_Take care of your health, read your books and be patient, because  life is woven more from troubles and sufferings, but one must avoid them, and when one cannot avoid them then one must  fight, overcome them, and succeed, without being knocked over.
_

## The backstory
Constance was born in 1892 in Arges County, Romania, into a relatively well-off, land-owning family. 
<figure>
<img src="./post3/family.png"
     style="display: block; margin: auto; width: 400px" />
     <figcaption style="text-align: center;" ><i> Constance as a girl and her family, that would be my great-grandmother and great-great-grandparents.
	 It must have been around 1910
	 </i></figcaption>
</figure>
She became a teacher, a much respected profession back then, especially in a village. Constance married a fellow teacher, Pârvu. 
Pârvu fought in WW1 as an officer and was killed in action in 1917 while Constance was pregnant.
 His story, including the beautiful books and notes he left behind, is [here](./#bookswar).
<figure>
<img src="./post3/constanta.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Constance, my great-grandmother, as a young woman in traditional costume, probably from 1910-1920, my colorisations.
</i></figcaption>

Their daughter, Aurelia, was born in January 1918. She grew up in the village and went to Bucharest as soon as she turned 18. 
After a few eventful years in the capital
(she worked as a secretary to a politician that went on to become PM before 
he was assasinated by a pro-Nazi militia in 1939), the beginning of the Second World War brought Aurelia back to the village of her birth. 
Aurelia met and, in 1944, married a man called Constantin. 
<figure>
<img src="./post3/bunici.png"
style="display: block; margin: auto; width: 400px" />
<figcaption style="text-align: center;" ><i> Aurelia and Constantin, my maternal grandparents in early 1945.
</i></figcaption>
</figure>
Aurelia later got pregnant with Mariana, my mother. Tragically, Aurelia died in childbirth in December 1945, leaving a distraught Constance to care for her newborn granddaughter. 
<br/>
Constance raised her grand-daughther, Mariana, for the next 10 years like a single mother.
This was the second time around for Constance, after being a single, widowed mother
to her daughther, Aurelia.
Mariana went to live with her father and new stepmother from 1956.<br> 

After WWII, the communists came into power in Romania. Anyone well off was labelled enemy of the people regardless of anything else, like being a war widow and a teacher.
 All Constance's land was confiscated and her house, the best in the village, was requisitioned for the use of party officials. 
She was evicted from her house. Grandmother and granddaughter were allowed into one of the unheated outbuildings. 
Later they could live in the attic of the house. Later still, around 1965, Constance regained the use of her own house.<br/>
When Mariana was 10, her father asked her to move in with him and his second wife in the house in Bucharest.

Mariana visited her grandmother in the village during summer holidays and they wrote to each other from 1956 until Constance's death in 1968. 
<figure>
<img src="./post3/candm.png"
style="display: block; margin: auto; width: 300px" />
<figcaption style="text-align: center;" ><i> Constanța and Mariana in front of their house, most likely the summer of 1964.
</i></figcaption>
</figure>
The yellowing pages preserve Constance's spirit, her erudition, her love and wisdom. 
She died before I was born but I feel close to her because of those letters.
The fact that some of my nearest and dearest found similirarities between her writing and mine, her spirit and mine is 
deeply touching for me.
Since I discoved then, I come back to these letters often to find solace and inspiration. 

`
	content = engText
	}
	const summary = Post3Summary(lang)
    return {
         content,
         linkId: summary.linkId,
         title: summary.cardTitle
    }
}
export default Post3;
