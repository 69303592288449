
import { Container, Row, Nav, Stack, Accordion } from 'react-bootstrap';
import { Link } from '@mui/material';
import React from 'react';
import { Dimensions } from 'react-native';
import "./carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import InstagramIcon from '@mui/icons-material/Instagram';
const ArtPage = ({ urlSearchParameters, lang, linkId, text, leftLinkId, leftLinkText, rightLinkId, rightLinkText, likes, comments, onLikesUpdated, onCommentsUpdated }) => {
    let isLarge = true
    const windowDimensions = Dimensions.get('window');
    if (windowDimensions.width < 800) {
        isLarge = false
    }
    return (
        <>
            <Container style={{ height: '100%' }}>
                <br />
                <h3>
                    My artistic experiments
                </h3>
                <br />
                <Stack gap={3}>
                    <h5>Sketches in charcoal and coloured pencils</h5>

                    {isLarge ?
                        <Carousel interval="5000" infiniteLoop="true">
                            <div>
                                <img src="../art/sketch/_colour.png" />

                            </div>
                            <div>
                                <img src="../art/sketch/_portraits.png" />
                            </div>
                            <div>
                                <img src="../art/sketch/_mix.png" />
                            </div>
                            <div>
                                <img src="../art/sketch/_mix2.png" />

                            </div>
                            <div>
                                <img src="../art/sketch/_portraits2.png" />
                            </div>

                        </Carousel> :
                        <Carousel interval="5000" infiniteLoop="true">

                            <div>
                                <img src="../art/sketch/square/brown.png" />

                            </div>

                            <div>
                                <img src="../art/sketch/square/objects.png" />
                            </div>
                            <div>
                                <img src="../art/sketch/square/objects2.png" />
                            </div>
                            <div>
                                <img src="../art/sketch/square/portraits2.png" />
                            </div>
                            <div>
                                <img src="../art/sketch/square/mix.png" />
                            </div>
                        </Carousel>
                    }
                    <h5>Crafts - Upcycling, sewing, collage, unusual displays</h5>
                    {isLarge ?
                        <Carousel interval="5000" infiniteLoop="true">
                            <div>
                                <img src="../art/crafts/crafts1.png" />

                            </div>
                            <div>
                                <img src="../art/crafts/crafts2.png" />
                            </div>


                        </Carousel> :
                        <Carousel interval="5000" infiniteLoop="true">
                            <div>
                                <img src="../art/crafts/square/crafts1.png" />

                            </div>

                            <div>
                                <img src="../art/crafts/square/crafts2.png" />
                            </div>

                        </Carousel>
                    }
                    <br></br>
                    <h5>A bit of writing</h5>
                    <p> At various times in my life, I tried different styles of writing (see  {<Link href={`../?lang=${lang}`}> main page </Link>}
                        for more 😊). Here are some examples.
                        The short story included here is my only bit of published fiction.
                    </p>
                    <Accordion defaultActiveKey="6" >
                        <Accordion.Item eventKey="a">
                            <Accordion.Header>Short Story</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    This short story appeared in a local magazine, Hampshire View, in April 2008.
                                </p>
                                <br></br>
                                <h4> They are not small forever</h4>

                                <p>
                                    “Do I have everything?".  I don’t mean this to be a philosophical,
                                    soul-searching question; more a practical bag-searching query.  It's another
                                    normal day for me and, once again, I am preparing to leave my house for a
                                    walk into town with my six month old twins.   As always, I am prepared for
                                    all seasons: pouring rain, blistering sun, biting cold or piercing winds.
                                    For the little ones I must be prepared for any eventualities they encounter:
                                    hunger, thirst, boredom, as well as bodily functions.  All present and
                                    correct we leave our terraced victorian house and begin our trek to the high
                                    street.
                                </p>
                                <p>
                                    Town is a short walk taking in Hyde and Jewry Street.  Hardly have we
                                    started however and bump into, with an apologetic crash of prams, our
                                    neighbour Carrie.  She's the mother of two adorable toddlers and today is
                                    accompanied by her ex.  An ironic grouping considering that Carrie is a high
                                    powered town solicitor specializing in divorces herself.  Glancing at
                                    Carrie's figure I find myself about to congratulate her on having a third
                                    child on the way, however something stops me from mentioning this.  Mercy
                                    that my tired mind that morning still has its "embarassing comments"
                                    firewell set to high risk altert mode.  The conversation instead is more
                                    routine.
                                </p>
                                <i>“Hi, so nice to see you! How are the sweet darlings?”</i><br></br>
                                <i>“Really good" </i>, replies Carrie, adding <i>"they're so excited about their new
                                    brother or sister.”</i>

                                Phew, now I know the reason for Carrie's tummy bulge I can relax.  I need to
                                add that rule to my firewall.

                                <i>“Congratulations! You don’t show at all, I would have never guessed! Three!!
                                    I find it so hard with my two! Don’t you miss the old days? The court, power
                                    suits, winning a case?” </i>

                                Carrie pauses to consider this question.  She replies in a soft voice, so
                                none of the children will hear.

                                <i>“No, I feel really fulfilled and accomplished now"</i>, leaning forward to add
                                <i>".. in such a profound way, so much, much, more than before“.</i>

                                I feel moved by her answer and momentarily don't know how to respond.
                                Carrie isn't finished with her answer though and as an epilogue adds,

                                <i>“They won’t be small for ever, you know?   It goes so quickly, such a
                                    special time in their lives and they really need their mother now… Feels
                                    like yesterday when I had Annie"</i>.

                                Realizing she's on a roll Carrie finishes with the question every mother has
                                to deal with

                                <i>"Why, you aren’t thinking of work, already, are you?”</i>
                                <p>
                                    I can't remember the answer I gave, but at that moment I felt like I was in
                                    the court of motherhood, being cross examined by Carrie the power solicitor
                                    and super mom while the jury of assembled toddlers, newborns, and children
                                    to be waited for my answer.  My boy's eyes looked up at me with a gaze that
                                    in that moment captured all their smiles, their laughs, their tears, and our
                                    shared future together.  No words can explain that feeling; the emotion of
                                    motherhood has no equal on this earth.  Love is not a strong enough word to
                                    even come close.
                                    Crashing from the courtroom of dreams to the pavement I am awakened when one
                                    of my cherubs starts to cry.  A hurried goodbye to Carrie and we wend our
                                    way to the town centre.
                                </p>
                                <p>
                                    Our arrival time coincides with lunch break for the workers of Winchester
                                    and our next encounter is Steve from my old office; master geek, creative
                                    know-it-all, uber inventor, thought leader, and so forth.  Despite his
                                    brilliance in these fields he is clearly clueless about babies, once
                                    expected them to be fully walking and talking at 5 months old.  I shield him
                                    baby talk this time trying to find out what's going on back at work.  Steve
                                    starts arm waving about something or other that seems to involve a lot of
                                    acronyms and obscure terms that, once upon a time, I was versant in and made
                                    up most of my waking day.   Today however it just washes over me and fails
                                    to excite me in the slightest, having the rather unfortunate effect of
                                    making me feel suddenly extremely bored and tired.
                                </p>
                                <i>
                                    "So, when are you coming back to work"</i>, Steve asks me with a cheerful grin.

                                Two cross examinations in one day.  This time my crime however is not
                                finding interesting a world with e-mails, phone calls, deadlines, meetings,
                                internal politics, and so forth.  It's a world Steve clearly relishes
                                drowning in each day in the office so my answer must be careful not to upset
                                him.

                                <i>“Errr, not sure... My babies are still so tiny, so young”.</i>  I feel pleased
                                at my reply because it sort of absolves me from the decision.

                                Steve, not only clueless about the pace of infant development, has no
                                concept of what it means to be a mother or parent.  He's an engineer though
                                so every problem has a logical solution.  If I can't work because the babies
                                are young his reponse is predictably,

                                <i>“But they won’t be like that forever, surely ?"</i>

                                Nice one Steve.  Yup, they'll grow up soon I can slide back into the humdrum
                                of office life while I watch them becoming boisterous teenagers.

                                Steve senses his answer might have been insenstive.  <i>"Up to you obviously, I
                                    did not mean,  What I meant is ...”</i>

                                This getting worse.  I spare Steve from trying to dig his way out by telling
                                him I have to hurry to the doctor so the little ones can get immunized and
                                we bid our farewells.  My trek becomes more ardous as I navigate blocked
                                pavements, big issue hawkers, a pushchair breakdown, finally forcing me to
                                strike camp by the City Cross as I sit down to catch my breath.  I need to
                                update my checklist next time; leave behind the sun cream in October but
                                check the battery charge.
                                <p>
                                    A familiar voice chimes in, <i> “Hi there!”</i>.

                                    It's Jenny, my neighbour, who seems to have fared the high street better
                                    than me this morning.

                                    <i>”Look at them. Haven’t they grown! Aren’t they sweet?”</i>

                                    I've had enough of deep and meaningful conversations about motherhood versus
                                    careerhood.  I just want to get my pushchair fixed so I can get home before
                                    the rain gets any worse.  Jenny becomes very practical all of a sudden.
                                    While Steve the engineer is back at work now sending e-mails,  Jenny and I
                                    are ironically looking at the underside of a pushchair trying to fix
                                    something that shouldn't need fixing.
                                </p>
                                <i>"Aha, I see"</i>, Jenny talks reassuringly as she works away, <i>"My niece had one
                                    like this.  There is a button just above the wheel that blocks it. The rain
                                    cover must have pushed it.  Let me try pushing this big and pulling this bit
                                    at the same time".</i>

                                There is a clicking noise and I try the wheel again.  It's fixed.  The rain
                                stops at the same time.  Jenny is a saint and I reward her with an
                                invitation to come back for a coffee.  On the way to Hyde via River Park and
                                the arching plane trees I ask Jenny about her family, now grown up.

                                <i>"Tony"</i>, Jenny's eldest she explains,<i> "is teaching at St Swithun and about to
                                    get married next summer. Sam works in publishing in London and moved in with
                                    some friends in a new flat, and Jo has left for Southampton University".</i>

                                Looking at my little ones as I push them past the rugby fields Jenny clearly
                                reminisces back to the times when she too worried about nap times and
                                nappies rather than napster and homework on Napoleon.
                                <i>
                                    "I can't believe they've all fled the nest now.  It's so hard to believe
                                    when they are so young like your two". </i> Jenny is in freestyle memory lane
                                now and adds,<i> "They're not small forever, that's what you have to remember,
                                    it goes so quickly, so quickly".</i>
                                <br></br>
                                <p>
                                    What does a mother do when babies have become children who have become
                                    teenagers who have become adults ?  Jenny answers me without being prompted.

                                    <i>"What will I do with myself the whole day after all this time ?".</i>

                                    Surely she has plans that she put on ice before the babies arrived.

                                    <i>"I think"</i>, Jenny pauses to think, <i>"that I'll get a degree, join the Wildlife
                                        trust, catch up with friends, join the choir, ..."</i>.   It's like an
                                    outpouring of all her dreams, her plans, her ambitions, and her goals that
                                    she had to suspend when she became a mother.   I feel a sense of regret in
                                    her, that her children somehow stopped her from doing all the stuff she
                                    really wanted to that, now she has her days back to herself, she can resume.
                                    Maybe she'll appreciate them more this time around.
                                </p>
                                <p>
                                    That night I start to write a fresh to-do-list adding:  "Don't forget
                                    friends,  don't stop hobbies,  enjoy the twins while they're still young,
                                    don't worry about the career going on hold".  It's a good set to be going
                                    with, however at the top I add the more urgent items, "Find and read the
                                    pushchair manual".
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="b">
                            <Accordion.Header>Sonnets for my sons</Accordion.Header>
                            <Accordion.Body>
                                <p>
                                    When my boys were in year 8, they had a homework to write a sonnet on a topic of their choosing.
                                    I remember helping them and trying to find rhymes for table tennis!
                                    The experience inspired me to write these two sonnets referencing various events of their childhood.
                                </p>
                                <br></br>
                                <h4>Sonnet 1</h4>
                                <p>
                                    My dear child, I am, oh, so proud of you <br></br>
                                    Your charm, your energy and passion. <br></br>
                                    You learn, you play, you care, you love too <br></br>
                                    And look after others with compassion.
                                </p>

                                <p>
                                    From my chubby baby with his round cheeks <br></br>
                                    To the child of six who won his mission <br></br>
                                    To see on stage how his Miss Moffet speaks <br></br>
                                    To my tween now full of big ambition.
                                </p>

                                <p>
                                    I know you want me to watch how you play, <br></br>
                                    But I cannot help you, and what if you lose?<br></br>
                                    I know that you will get what you want one day<br></br>
                                    And a good win will come after each bruise.
                                </p>
                                <p>
                                    Just two more wishes, please: clean up your mess! <br></br>
                                    And you and your brother should argue less!
                                </p>

                                <h4>Sonnet 2</h4>
                                <p>
                                    My dear child, I am, oh, so proud of you <br></br>
                                    Smart, kind, funny, if always a bit shy, <br></br>
                                    My first born by a minute or two <br></br>
                                    A small baby, with big eyes, ready to cry.
                                </p>
                                <p>
                                    The tears were near, but you were first <br></br>
                                    To solve that puzzle and always ask why. <br></br>
                                    My heart stopped when you fell, it was the worst<br></br>
                                    But you were fine and the partridges flew high.
                                </p>
                                <p>
                                    Who knew you'd bake us all those cakes!<br></br>
                                    I was so happy when you made the team! <br></br>
                                    The math, the sport, the drums and all it takes <br></br>
                                    And mother and son programming dream.
                                </p>
                                <p>
                                    Just two more wishes, please: clean up your mess! <br></br>
                                    And you and your brother should argue less!
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <br />
                    <h5>Shapes and colours</h5>
                    <p>Watercolours and sharpies: geometric shapes and illustrations for my children stories</p>
                    {isLarge ?
                        <Carousel interval="5000" infiniteLoop="true">
                            <div>
                                <img src="../art/paintings/wispy1.png" />

                            </div>
                            <div>
                                <img src="../art/paintings/sharpies.png" />

                            </div>

                            <div>
                                <img src="../art/paintings/patterns.png" />
                            </div>

                            <div>
                                <img src="../art/paintings/wispy2.png" />
                            </div>

                        </Carousel> :
                        <Carousel interval="5000" infiniteLoop="true">
                            <div>
                                <img src="../art/paintings/square/wispy1.png" />
                            </div>
                            <div>
                                <img src="../art/paintings/square/patterns.png" />

                            </div>

                            <div>
                                <img src="../art/paintings/square/sharpies.png" />
                            </div>
                            <div>
                                <img src="../art/paintings/square/wispy2.png" />
                            </div>

                        </Carousel>
                    }

                    <h5>Photography</h5>
                    <p>I got a camera relatively late, in my early twenties. Since then, I liked to snap-snap-snap and record moments and emotions.
                        <br></br>
                        <Link href="https://www.instagram.com/doina.liliana/" target='_blank' style={{ color: "black" }}>
                            See more photos on my account <InstagramIcon />
                        </Link>
                    </p>

                    <Carousel interval="5000" infiniteLoop="true" >
                        <div>
                            <img src="../art/photo/1.png" />

                        </div>
                        <div>
                            <img src="../art/photo/2.png" />

                        </div>
                        <div>
                            <img src="../art/photo/3.png" />

                        </div>
                        <div>
                            <img src="../art/photo/4.png" />

                        </div>
                        <div>
                            <img src="../art/photo/5.png" />

                        </div>
                        <div>
                            <img src="../art/photo/6.png" />

                        </div>
                    </Carousel>

                    <h5>Home treasure trails</h5>
                    <p>
                        This is an incomplete project for a game idea - treasure trails for homes and gardens.
                        <Link href="https://www.myhometrails.com/home" target='_blank' style={{ color: "black" }}>
                            myhometrails.com
                        </Link>
                    </p>

                    <Carousel interval="5000" infiniteLoop="true" >
                        <div>
                            <img src="../art/game/game1.png" />

                        </div>
                        <div>
                            <img src="../art/game/game2.png" />

                        </div>
                        <div>
                            <img src="../art/game/game3.png" />

                        </div>

                    </Carousel>



                    {/* <p>~~~~~~~~~~~~OR~~~~~~~~~~~~~~</p>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Drawings</Accordion.Header>
                            <Accordion.Body>
                                <h4>Some of my sketches</h4>
                                {isLarge ?
                                    <Carousel  interval="5000" infiniteLoop="true">
                                        <div>
                                            <img src="../art/sketch/_colour.png" />

                                        </div>
                                        <div>
                                            <img src="../art/sketch/_portraits.png" />
                                        </div>
                                        <div>
                                            <img src="../art/sketch/_mix.png" />
                                        </div>
                                        <div>
                                            <img src="../art/sketch/_mix2.png" />

                                        </div>
                                        <div>
                                            <img src="../art/sketch/_portraits2.png" />
                                        </div>

                                    </Carousel> :
                                    <Carousel  interval="5000" infiniteLoop="true">

                                        <div>
                                            <img src="../art/sketch/square/brown.png" />

                                        </div>

                                        <div>
                                            <img src="../art/sketch/square/objects.png" />
                                        </div>
                                        <div>
                                            <img src="../art/sketch/square/objects2.png" />
                                        </div>
                                        <div>
                                            <img src="../art/sketch/square/portraits2.png" />
                                        </div>
                                        <div>
                                            <img src="../art/sketch/square/mix.png" />
                                        </div>
                                    </Carousel>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Shapes and colours</Accordion.Header>
                            <Accordion.Body>
                                <h4>Watercolours and sharpies; shapes and illustrations of children stories</h4>
                                {isLarge ?
                                    <Carousel  interval="5000" infiniteLoop="true">
                                        <div>
                                            <img src="../art/paintings/sharpies.png" />

                                        </div>

                                        <div>
                                            <img src="../art/paintings/patterns.png" />
                                        </div>
                                        <div>
                                            <img src="../art/paintings/wispy1.png" />

                                        </div>
                                        <div>
                                            <img src="../art/paintings/wispy2.png" />
                                        </div>

                                    </Carousel> :
                                    <Carousel  interval="5000" infiniteLoop="true">
                                        <div>
                                            <img src="../art/paintings/square/patterns.png" />

                                        </div>

                                        <div>
                                            <img src="../art/paintings/square/sharpies.png" />
                                        </div>
                                        <div>
                                            <img src="../art/paintings/square/wispy1.png" />
                                        </div>
                                        <div>
                                            <img src="../art/paintings/square/wispy2.png" />
                                        </div>

                                    </Carousel>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>A bit of writing</Accordion.Header>
                            <Accordion.Body>
                                <h4>A bit of writing</h4>
                                At various times in my life, I tried different kinds of writing.
                                Here are some examples.
                                <Accordion defaultActiveKey="6" >
                                    <Accordion.Item eventKey="a">
                                        <Accordion.Header>Short Story</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                This short story appeared in a local magazine, Hampshire View in April 2008.
                                            </p>
                                            <br></br>
                                            <h4> They are not small forever</h4>

                                            <p>
                                                “Do I have everything?".  I don’t mean this to be a philosophical,
                                                soul-searching question; more a practical bag-searching query.  It's another
                                                normal day for me and, once again, I am preparing to leave my house for a
                                                walk into town with my six month old twins.   As always, I am prepared for
                                                all seasons: pouring rain, blistering sun, biting cold or piercing winds.
                                                For the little ones I must be prepared for any eventualities they encounter:
                                                hunger, thirst, boredom, as well as bodily functions.  All present and
                                                correct we leave our terraced victorian house and begin our trek to the high
                                                street.
                                            </p>
                                            <p>
                                                Town is a short walk taking in Hyde and Jewry Street.  Hardly have we
                                                started however and bump into, with an apologetic crash of prams, our
                                                neighbour Carrie.  She's the mother of two adorable toddlers and today is
                                                accompanied by her ex.  An ironic grouping considering that Carrie is a high
                                                powered town solicitor specializing in divorces herself.  Glancing at
                                                Carrie's figure I find myself about to congratulate her on having a third
                                                child on the way, however something stops me from mentioning this.  Mercy
                                                that my tired mind that morning still has its "embarassing comments"
                                                firewell set to high risk altert mode.  The conversation instead is more
                                                routine.
                                            </p>
                                            <i>“Hi, so nice to see you! How are the sweet darlings?”</i><br></br>
                                            <i>“Really good" </i>, replies Carrie, adding <i>"they're so excited about their new
                                                brother or sister.”</i>

                                            Phew, now I know the reason for Carrie's tummy bulge I can relax.  I need to
                                            add that rule to my firewall.

                                            <i>“Congratulations! You don’t show at all, I would have never guessed! Three!!
                                                I find it so hard with my two! Don’t you miss the old days? The court, power
                                                suits, winning a case?” </i>

                                            Carrie pauses to consider this question.  She replies in a soft voice, so
                                            none of the children will hear.

                                            <i>“No, I feel really fulfilled and accomplished now"</i>, leaning forward to add
                                            <i>".. in such a profound way, so much, much, more than before“.</i>

                                            I feel moved by her answer and momentarily don't know how to respond.
                                            Carrie isn't finished with her answer though and as an epilogue adds,

                                            <i>“They won’t be small for ever, you know?   It goes so quickly, such a
                                                special time in their lives and they really need their mother now… Feels
                                                like yesterday when I had Annie"</i>.

                                            Realizing she's on a roll Carrie finishes with the question every mother has
                                            to deal with

                                            <i>"Why, you aren’t thinking of work, already, are you?”</i>
                                            <p>
                                                I can't remember the answer I gave, but at that moment I felt like I was in
                                                the court of motherhood, being cross examined by Carrie the power solicitor
                                                and super mom while the jury of assembled toddlers, newborns, and children
                                                to be waited for my answer.  My boy's eyes looked up at me with a gaze that
                                                in that moment captured all their smiles, their laughs, their tears, and our
                                                shared future together.  No words can explain that feeling; the emotion of
                                                motherhood has no equal on this earth.  Love is not a strong enough word to
                                                even come close.
                                                Crashing from the courtroom of dreams to the pavement I am awakened when one
                                                of my cherubs starts to cry.  A hurried goodbye to Carrie and we wend our
                                                way to the town centre.
                                            </p>
                                            <p>
                                                Our arrival time coincides with lunch break for the workers of Winchester
                                                and our next encounter is Steve from my old office; master geek, creative
                                                know-it-all, uber inventor, thought leader, and so forth.  Despite his
                                                brilliance in these fields he is clearly clueless about babies, once
                                                expected them to be fully walking and talking at 5 months old.  I shield him
                                                baby talk this time trying to find out what's going on back at work.  Steve
                                                starts arm waving about something or other that seems to involve a lot of
                                                acronyms and obscure terms that, once upon a time, I was versant in and made
                                                up most of my waking day.   Today however it just washes over me and fails
                                                to excite me in the slightest, having the rather unfortunate effect of
                                                making me feel suddenly extremely bored and tired.
                                            </p>
                                            <i>
                                                "So, when are you coming back to work"</i>, Steve asks me with a cheerful grin.

                                            Two cross examinations in one day.  This time my crime however is not
                                            finding interesting a world with e-mails, phone calls, deadlines, meetings,
                                            internal politics, and so forth.  It's a world Steve clearly relishes
                                            drowning in each day in the office so my answer must be careful not to upset
                                            him.

                                            <i>“Errr, not sure... My babies are still so tiny, so young”.</i>  I feel pleased
                                            at my reply because it sort of absolves me from the decision.

                                            Steve, not only clueless about the pace of infant development, has no
                                            concept of what it means to be a mother or parent.  He's an engineer though
                                            so every problem has a logical solution.  If I can't work because the babies
                                            are young his reponse is predictably,

                                            <i>“But they won’t be like that forever, surely ?"</i>

                                            Nice one Steve.  Yup, they'll grow up soon I can slide back into the humdrum
                                            of office life while I watch them becoming boisterous teenagers.

                                            Steve senses his answer might have been insenstive.  <i>"Up to you obviously, I
                                                did not mean,  What I meant is ...”</i>

                                            This getting worse.  I spare Steve from trying to dig his way out by telling
                                            him I have to hurry to the doctor so the little ones can get immunized and
                                            we bid our farewells.  My trek becomes more ardous as I navigate blocked
                                            pavements, big issue hawkers, a pushchair breakdown, finally forcing me to
                                            strike camp by the City Cross as I sit down to catch my breath.  I need to
                                            update my checklist next time; leave behind the sun cream in October but
                                            check the battery charge.
                                            <p>
                                                A familiar voice chimes in, <i> “Hi there!”</i>.

                                                It's Jenny, my neighbour, who seems to have fared the high street better
                                                than me this morning.

                                                <i>”Look at them. Haven’t they grown! Aren’t they sweet?”</i>

                                                I've had enough of deep and meaningful conversations about motherhood versus
                                                careerhood.  I just want to get my pushchair fixed so I can get home before
                                                the rain gets any worse.  Jenny becomes very practical all of a sudden.
                                                While Steve the engineer is back at work now sending e-mails,  Jenny and I
                                                are ironically looking at the underside of a pushchair trying to fix
                                                something that shouldn't need fixing.
                                            </p>
                                            <i>"Aha, I see"</i>, Jenny talks reassuringly as she works away, <i>"My niece had one
                                                like this.  There is a button just above the wheel that blocks it. The rain
                                                cover must have pushed it.  Let me try pushing this big and pulling this bit
                                                at the same time".</i>

                                            There is a clicking noise and I try the wheel again.  It's fixed.  The rain
                                            stops at the same time.  Jenny is a saint and I reward her with an
                                            invitation to come back for a coffee.  On the way to Hyde via River Park and
                                            the arching plane trees I ask Jenny about her family, now grown up.

                                            <i>"Tony"</i>, Jenny's eldest she explains,<i> "is teaching at St Swithun and about to
                                                get married next summer. Sam works in publishing in London and moved in with
                                                some friends in a new flat, and Jo has left for Southampton University".</i>

                                            Looking at my little ones as I push them past the rugby fields Jenny clearly
                                            reminisces back to the times when she too worried about nap times and
                                            nappies rather than napster and homework on Napoleon.
                                            <i>
                                                "I can't believe they've all fled the nest now.  It's so hard to believe
                                                when they are so young like your two". </i> Jenny is in freestyle memory lane
                                            now and adds,<i> "They're not small forever, that's what you have to remember,
                                                it goes so quickly, so quickly".</i>
                                            <br></br>
                                            <p>
                                                What does a mother do when babies have become children who have become
                                                teenagers who have become adults ?  Jenny answers me without being prompted.

                                                <i>"What will I do with myself the whole day after all this time ?".</i>

                                                Surely she has plans that she put on ice before the babies arrived.

                                                <i>"I think"</i>, Jenny pauses to think, <i>"that I'll get a degree, join the Wildlife
                                                    trust, catch up with friends, join the choir, ..."</i>.   It's like an
                                                outpouring of all her dreams, her plans, her ambitions, and her goals that
                                                she had to suspend when she became a mother.   I feel a sense of regret in
                                                her, that her children somehow stopped her from doing all the stuff she
                                                really wanted to that, now she has her days back to herself, she can resume.
                                                Maybe she'll appreciate them more this time around.
                                            </p>
                                            <p>
                                                That night I start to write a fresh to-do-list adding:  "Don't forget
                                                friends,  don't stop hobbies,  enjoy the twins while they're still young,
                                                don't worry about the career going on hold".  It's a good set to be going
                                                with, however at the top I add the more urgent items, "Find and read the
                                                pushchair manual".
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="b">
                                        <Accordion.Header>Sonnets for my sons</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                When my boys were in year 8, they had a homework to write a sonnet on a topic of their choosing.
                                                I remember helping them and trying to find rhymes for table tennis!
                                                The experience inspired me to write two sonnets for each that reference various events of their childhood.
                                            </p>
                                            <br></br>
                                            <h4>Sonnet 1</h4>
                                            <p>
                                                My dear child, I am, oh, so proud of you <br></br>
                                                Your charm, your energy and passion. <br></br>
                                                You learn, you play, you care, you love too <br></br>
                                                And look aftet others with compassion.
                                            </p>

                                            <p>
                                                From my chubby baby with his round cheeks <br></br>
                                                To the child of six who won his mission <br></br>
                                                To see on stage how his Miss Moffet speaks <br></br>
                                                To my tween now full of big ambition.
                                            </p>

                                            <p>
                                                I know you want me to watch how you play, <br></br>
                                                But I cannot help you and what if you lose.<br></br>
                                                I know that you will get what you want one day<br></br>
                                                And a good win will come after each bruise.
                                            </p>
                                            <p>
                                                Just two more wishes, please: clean up your mess! <br></br>
                                                And you and your brother should argue less!
                                            </p>

                                            <h4>Sonnet 2</h4>
                                            <p>
                                                My dear child, I am, oh, so proud of you <br></br>
                                                Smart, kind, funny, if always a bit shy, <br></br>
                                                My first born by a minute or two <br></br>
                                                A small baby, with big eyes, ready to cry.
                                            </p>
                                            <p>
                                                The tears were near, but you were first <br></br>
                                                To solve that puzzle and always ask why. <br></br>
                                                My heart stopped when you fell, it was the worst<br></br>
                                                But you were fine and the partridges flew high
                                            </p>
                                            <p>
                                                Who knew you'd bake us all those cakes!<br></br>
                                                I was so happy when you made the team! <br></br>
                                                The math, the sport, the drums and all it takes <br></br>
                                                And mother and son programming dream/
                                            </p>
                                            <p>
                                                Just two more wishes, please: clean up your mess! <br></br>
                                                And you and your brother should argue less!
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Photography</Accordion.Header>
                            <Accordion.Body>
                                I got a camera relatively late, in my early twenties. Since then, I liked to snap-snap-snap and record moments and emotions.
                                <h4>
                                    <Nav.Link href="https://www.instagram.com/doina.liliana/" target='_blank' style={{ color: "black" }}>
                                        See more photos on my account <InstagramIcon />
                                    </Nav.Link>
                                </h4>

                                <Carousel  interval="5000" infiniteLoop="true" >
                                    <div>
                                        <img src="../art/photo/1.png" />

                                    </div>
                                    <div>
                                        <img src="../art/photo/2.png" />

                                    </div>
                                    <div>
                                        <img src="../art/photo/3.png" />

                                    </div>
                                    <div>
                                        <img src="../art/photo/4.png" />

                                    </div>
                                    <div>
                                        <img src="../art/photo/5.png" />

                                    </div>
                                    <div>
                                        <img src="../art/photo/6.png" />

                                    </div>
                                </Carousel>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Home Treasure Trails</Accordion.Header>
                            <Accordion.Body>
                                <h4 >
                                    This is an incomplete project for a game idea - treasure trails for homes and gardens
                                    <br />
                                    <Link href="https://www.myhometrails.com/home" target='_blank' style={{ color: "black" }}>
                                        myhometrails.com
                                    </Link>
                                </h4>

                                <Carousel  interval="2000" infiniteLoop="true" >
                                    <div>
                                        <img src="../art/game/game1.png" />

                                    </div>
                                    <div>
                                        <img src="../art/game/game2.png" />

                                    </div>
                                    <div>
                                        <img src="../art/game/game3.png" />

                                    </div>

                                </Carousel>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Crafts</Accordion.Header>
                            <Accordion.Body>
                                <h4>A bit of upcycling, sewing, creative displays of precious objects</h4>
                                {isLarge ?
                                    <Carousel  interval="5000" infiniteLoop="true">
                                        <div>
                                            <img src="../art/crafts/crafts1.png" />

                                        </div>
                                        <div>
                                            <img src="../art/crafts/crafts2.png" />
                                        </div>


                                    </Carousel> :
                                    <Carousel  interval="5000" infiniteLoop="true">
                                        <div>
                                            <img src="../art/crafts/square/crafts1.png" />

                                        </div>

                                        <div>
                                            <img src="../art/crafts/square/crafts2.png" />
                                        </div>

                                    </Carousel>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion> */}
                    <h4> Back to
                        {<Link href={`../?lang=${lang}`}> Main Page</Link>}

                    </h4>
                    <br></br>      <br></br>
                </Stack>
                <Row className="justify-content-between">

                </Row>
            </Container>
        </>
    )
};



// const ArtPage = ({ urlSearchParameters, lang, linkId, text, leftLinkId, leftLinkText, rightLinkId, rightLinkText, likes, comments, onLikesUpdated, onCommentsUpdated }) => {

//     return (
//         <>
//             <Container>
//                 <br/>
//                 <br/>
//                 Recipe 1
//                 ...
//                 Recipe 2
//                 ...
//             </Container>
//         </>
//     )
// }


export default ArtPage;