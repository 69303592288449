
import Post8Summary from "../summary/Post8Summary";

const Post8 = (lang) => {
	let content
	if (lang === 'rom') {
		const romText = `
# Ceva de privit

La fel ca mulți oameni de o anumită vârstă, fără îndoială, dintotdeauna, 
mă gândesc uneori cât de mult s-au schimbat lucrurile în timpul vieții mele. 
Dar, în afară de a spune cât de norocoși sunt tinerii de acum și cum nu știu cât de bine le este (deși sunt și, respectiv, nu stiu, evident!), 
o sa povestesc puțin cum se făceau lucrurile atunci și cum mă influențează astăzi.

O activitate frecventă în duminicile copilăriei mele (weekend-urile aveau o zi pe vremea aceea) erau vizitele la bunici, de obicei pentru masă. 
În timpul meselor, copiii trebuiau să mănânce și să tacă. 
În cazul nostru, nu existau veri cu care să ne jucam pentru mine și fratele meu, 
ci doar conversațiile adulților. Privind în urmă, nu stiu de ce nu am aveam măcar niște pixuri și hârtie sa ne facem de lucru. 

Am petrecut multe, ceea ce mi-au părut secole, uitându-mă la diferite obiecte, orice în câmpul meu vizual de la locul meu la masă.
Erau si unele lucruri hidoase, fără îndoială: vază aia îngrozitoare, vasul ala cu fructe urât rău, perdelele ala bune de aruncat. 
Dar au fost si câteva lucruri frumoase. 
Unele din ele le am acum, mă bucură, îmi sunt mai familiare decât multe din jucariile mele și ma transporta în acele vremuri.

Mergeam in vizita la bunicul matern și la a doua lui soție. Bunicul a trăit până la vârsta de 9 ani a mea. 
Apoi o vizitam pe soția lui, bunica mea vitregă, Pina. 
Pina a fost profesoară de limba română și directoare a Liceului Neculce. 
O colega de-a ei, profesoara de desen incerca sa se afirme ca artistă și Pina i-a cumpărat, aproape pentru nimic, cel puțin zece picturi, peisaje și natură 
statică. 

Am văzut că tablourile acestei artiste (numele îmi scapă acum) se vând acum cu câteva sute până la o mie de dolari, 
deci au o anumită valoare. 
Acestea au fost lucruri frumoase de privit. 
Majoritatea picturilor au fost mostenite de rudele Pinei, dar m-am bucurat că l-am primit pe acesta. 
Aș putea să descriu spun locul de pe perete unde se afla, cel mai aproape de dormitor. 
Deși este puțin convențional în subiect, îmi place, îi văd o calitate, ca să nu mai vorbim de amintiri.

<figure>
<img src="./post8/painting.png"
	style="display: block; margin: auto; width: 300px" />
</figure>

Ne-am vizitat și bunicii paterni și am mâncat în mica camera, si sufragerie si dormitor, pe care o aveau. 
Erau câteva lucruri de privit, cum ar fi fotografiile vechi pe perete, dar cea pe care mi-o amintesc 
cel mai mult este această icoana. 
Icoana este de fapt din familia mamei mele, ea le-a dăruit-o, ei erau oameni religioși. 
Icoana îi reprezintă pe împăratul Constantin și pe mama sa Elena, 
el fiind primul împărat roman care a trecut la creștinism și a încetat persecuția creștină.

<figure>
<img src="./post8/iconce.png"
	style="display: block; margin: auto; width: 300px" />
</figure>

Mi-a plăcut ortografia ciudată a numelui Constantin, caligrafia și amestecul de materiale și texturi, ceva hârtie, ceva broderie, ceva lemn. 
Scrie „Sfinții Împărați Constantin și Elena, rugați-Vă pentru noi”. Din nou, îmi amintesc poziția sa pe perete.
 Îmi amintesc că am avut gândul necreștin de ce trebuie să va plângeti, Constantine și Elena, pentru mine ar trebui să se roage oamenii, stând aici la nesfârsit. Iertare!

Cealaltă dată când aveai nevoie de ceva la care să te uiți a fost când erai bolnav, răcit sau gripat. 
Televizorul nu era o varianta, programul era doar de câteva ore pe zi și în mare parte propagandă comunistă. 
Evident că citeam când era suficient de mare și când era posibil, adica fără febră sau întrerupere de curent. 
Altminteri mai erau două obiecte la care să mă uit: unul era portretul bunicii mele, tânără, frumoasă, tragică.
Și apoi mai priveam această icoană destul de neobișnuită.

<figure>
<img src="./post8/icon.png"
	style="display: block; margin: auto; width: 300px" />
</figure>
De obicei, icoanele arată pe Maria și pe Pruncul Isus sau pe Isus binecuvântind. 
Această scenă în ulei pe lemn este o icoana deosbita dar și imaginea bolii copilăriei pentru mine.

Copiii de astăzi chiar nu știu nimic despre cât de bun observator te face plicitseala și despre ce atașamente formezi cu diverse obiecte în timp ce ești plictisit.
Tare îmi sunt dragi acesti camarazi ai plictiselilor trecute.
`
		content = romText
	} else { 
const postText = `
# Something to look at

Like many people of a certain age since, no doubt, forever, I think sometimes of how much things have changed during my lifetime. 
Tempting as it is to be saying how lucky today's young people are and how they don’t know how good they have it (though they are and they don’t, obviously and respectively) 
I will tell the story of certain objects from the past that are so significant for me today.

A frequent activity during the Sundays of my childhood (weekends were one day long at that time) were visits to the grandparents, usually for a meal. 

During the meals, children were supposed to eat and be quiet. In our particular case, there were no cousins to play with for my brother and I, just adult conversations. 
Looking back, I am not sure why we would not get at least some pens and paper, so instead I spent many, what felt like centuries, listening to the conversations and staring at various objects, 
anything I could see in my field of vision from my seat at the table.
There were some hideous things, for sure: this horrid vase, this clunky fruit bowl, the ghastly curtains. 
But there were a couple of nice things that I happily own now and that are more familiar to me than my toys because of this enforced observation during those visits.
Looking at these objects now brings be back to those visits as if I can still smell the food on the table.

We would visit my maternal grandfather and his second wife. 
He lived till I was 9 and then later we’d visit his wife, my step grandmother, Pina. 
Pina was a teacher of Romanian and head mistress of a big high school in Bucharest (Neculce). 
One of her colleagues, teaching art, was an aspiring, struggling artist at the time and Pina bought at least ten of this artist’s paintings, landscape and still life, for next to nothing. 
I saw that today this artist's paintings (the name escapes me now) sell for a few hundred up to a thousand dollars, so they have some value. 
I loved this paintings. This was the luxury version of meal time observation. 
Most of the paintings went to Pina’s relatives after her death but I was happy that we got this one below. 
I remember the precise spot on the wall where it hung, the closest to the bedroom. 
While the subject of the painting is a bit conventional, I like it, I see a quality to it, not to mention the memories.

<figure>
<img src="./post8/painting.png"
	style="display: block; margin: auto; width: 300px" />
</figure>

We would also visit my paternal grandparents and eat in their tiny living/bedroom. 
There were a few things to look at, like old photos on the wall but the one I remember the most is this icon. 
The icon is actually from my mother’s family; she gifted it to them. They were religious people. 
I will not attempt to explain why saints are so important in the Orthodox religion but they are and this one represents the emperor Constantine and his mother Elena, 
credited to being the first Roman emperor to convert to Christianity and to ceasing Christian persecution. 

<figure>
<img src="./post8/iconce.png"
	style="display: block; margin: auto; width: 300px" />
</figure>
I liked the weird (for Romanian) spelling of the name Constantine, the font and the mix of materials and textures, paper, embroidery, wood. 
It says “Holy Emperors Constantine and Elena, pray for us”. 
Again, I recall its position on the wall. 
I remember having the unchristian thought of what do Constantine and Elena have to complain about, people should be praying for me, 
stuck in here forever. Sorry!

The other time when you needed something to look at was when you were poorly, just normal stuff like colds, flu and bugs. Watching TV would have been the most obvious choice but, at that time, there was only a couple of hours a day of unwatchable communist propaganda. 
Obviously I’d read when I was old enough and when it was possible like I had no fever or there were no power cuts. 
Otherwise I would again study my surroundings and there were two particular objects to look at. 
One was a portrait of my grandmother, young, beautiful,  tragic, her story ever present in my life. And then there was this rather unusual icon, oil on wood.

<figure>
<img src="./post8/icon.png"
	style="display: block; margin: auto; width: 300px" />
</figure>
Usually Orthodox icons represent Mary and baby Jesus or Jesus giving his blessing. This night scene shows Jesus praying, possibly in the desert, and I think it’s quite an accomplished image.
It’s also the image of childhood illness for me.

The kids today really know nothing about how observant being bored makes you and what attachments you form while being bored.
I still love these companions of my past boredom.
`
    content = postText
	}


const summary = Post8Summary(lang)
return {
	content,
	linkId: summary.linkId,
	title: summary.cardTitle
}
}

export default Post8;
